import { UserContext } from '@/components/auth/UserContext';
import { NewAppLayout } from '@/components/layout/NewAppLayout';
import { useRouter } from 'next/router';
import { useContext } from 'react';
export default function IndexPage() {
  const router = useRouter();
  const user = useContext(UserContext);
  // const firstOrganization = user.organizations[0]

  // useEffect(() => {
  //   if (firstOrganization) {
  //     router.push(`/${firstOrganization.slug}`).catch(e => console.error(e))
  //   }
  // }, [router, firstOrganization])

  return <NewAppLayout data-sentry-element="NewAppLayout" data-sentry-component="IndexPage" data-sentry-source-file="index.tsx" />;
}